import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import { FiMenu } from 'react-icons/fi'
import logo from '../../static/images/favicon.png'

import Search from './Search/Search-index'
const searchIndices = [{ name: 'QUEERIERS_DU_CINEMA', title: `title` }]

const activeLinkStyle = {
  color: 'white',
}

const Header = styled.div`
  /* Small screens : */
  display: flex !important;
  flex-direction: column;
  background: ${props => props.theme.colors.primary};
  padding: 1rem 0;
  width: 100%;
  height: auto;
  transition: height 0.6s cubic-bezier(0.77, 0.2, 0.05, 1);
  /* Medium screens : */
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    padding: 1.5rem 0;
    /* height: 167px; */
  }
  /* Large screens : */
  @media screen and (min-width: 965px) {
    min-height: 80px;
    max-height: 100px;
  }
`

const Nav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  margin-top: 1.5rem;
  opacity: 1;
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    font-size: 1rem;
    li:first-of-type {
      font-family: ${props => props.theme.fonts.siteName};
      font-size: 1.1rem;
      text-decoration: underline;
    }
  }
  li {
    display: inline-block;
    margin-bottom: 0.9rem;
  }
  a {
    text-decoration: none;
    color: DarkGray;
    font-weight: 600;
    transition: all 0.2s;
    white-space: nowrap;
    &:hover {
      color: white;
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    align-items: center;
    max-width: ${props => props.theme.sizes.maxWidth};
    margin: 0 auto;
    padding: 0 1.5rem;
    ul {
      flex-direction: row;
      justify-content: space-evenly;
      flex-basis: 100%;
      flex-wrap: wrap;
    }
    li {
      margin-left: 1.7rem;
      margin-bottom: 0;
      &:first-of-type {
        position: relative;
        margin: 0;
        flex-basis: 100%;
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (min-width: 965px) {
    flex-direction: row;
    transform: none;
    ul {
      flex-wrap: nowrap;
    }
    li:first-of-type {
      text-align: left;
      margin-bottom: 0;
      margin-right: 4rem;
    }
  }
`

const SearchBar = styled.div`
  display: flex;
  position: relative;
  margin: auto;
  margin-top: 0.4rem;

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    margin-top: 1.3rem;
  }

  @media screen and (min-width: 965px) {
    margin: 0 0 0 6rem;
    input {
      display: block;
    }
  }
`

const Burger = styled.button`
  position: absolute;
  right: 5px;
  top: 4px;
  color: #fff;
  font-size: 2rem;
  transition: all 300ms;
  @media screen and (min-width: 965px) {
    display: none;
  }
`
const Logo = styled.img`
  display: none;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: block;
    position: absolute;
    height: 40px;
    /* width: 40px; */
    width: 45px;
    left: 168px;
    top: -10px;
    transform: rotate(-10deg);
    filter: drop-shadow(0px 0px 20px #353535);
  }
  @media screen and (min-width: 965px) {
    height: 70px;
    /* width: 70px; */
    /* left: -40px; */
    width: 77px;
    left: -49px;
    top: initial;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    height: 90px;
    /* width: 90px;
    left: -60px; */
    width: 102px;
    left: -90px;
  }
`

const Menu = () => {
  const { menuLinks } = useSiteMetadata()

  // Toggle Menu
  const [hiddeMenu, setshowMenu] = useState(true)
  const [classNavBar, setClassNavBar] = useState('')
  const [classHeader, setClassHeader] = useState('')
  const [classBurger, setclassBurger] = useState('')
  const toggleMenu = () => {
    hiddeMenu ? setClassNavBar('') : setClassNavBar('hiddeMenu')
    hiddeMenu ? setClassHeader('') : setClassHeader('hiddeHeader')
    hiddeMenu ? setclassBurger('') : setclassBurger('clickedBurger')
    setshowMenu(!hiddeMenu)
  }

  return (
    <Header className={classHeader}>
      <Nav className={classNavBar}>
        <Logo src={logo} alt="logo du site" />
        <ul>
          {menuLinks.map(link => (
            <li key={link.name}>
              <Link to={link.slug} activeStyle={activeLinkStyle}>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
        <SearchBar>
          <Search indices={searchIndices} />
        </SearchBar>
      </Nav>
      <Burger
        className={classBurger}
        onClick={() => toggleMenu()}
        aria-label="Voir menu"
      >
        <FiMenu />
      </Burger>
    </Header>
  )
}

export default Menu
