import { css } from '@emotion/core'
import 'fontsource-montserrat'
import 'fontsource-montserrat/600-normal.css'
import 'fontsource-montserrat-alternates/600-normal.css'

export const globalStyles = css`
         /* http://meyerweb.com/eric/tools/css/reset/
 v2.0 | 20110126
 License: none (public domain)
*/
         html,
         body,
         div,
         span,
         applet,
         object,
         iframe,
         h1,
         h2,
         h3,
         h4,
         h5,
         h6,
         p,
         blockquote,
         pre,
         a,
         abbr,
         acronym,
         address,
         big,
         cite,
         code,
         del,
         dfn,
         em,
         img,
         ins,
         kbd,
         q,
         s,
         samp,
         small,
         strike,
         strong,
         sub,
         sup,
         tt,
         var,
         b,
         u,
         i,
         center,
         dl,
         dt,
         dd,
         ol,
         ul,
         li,
         fieldset,
         form,
         label,
         legend,
         table,
         caption,
         tbody,
         tfoot,
         thead,
         tr,
         th,
         td,
         article,
         aside,
         canvas,
         details,
         embed,
         figure,
         figcaption,
         footer,
         header,
         hgroup,
         menu,
         nav,
         output,
         ruby,
         section,
         summary,
         time,
         mark,
         audio,
         video {
           margin: 0;
           padding: 0;
           border: 0;
           font-size: 100%;
           /* stylelint-disable-next-line */
           font: inherit;
           vertical-align: baseline;
         }

         /* Added to Fix Footer to bottom of viewport */
         html,
         body {
           background: white;
           height: 100%;
         }
         .siteRoot {
           height: 100vh;
           display: flex;
           flex-direction: column;
         }
         .siteContent {
           display: flex;
           flex-direction: column;
           flex: 1 0 auto;
         }
         footer {
           width: 100%;
         }

         /* End Fix to Place Footer on Bottom of Viewport */

         article,
         aside,
         details,
         figcaption,
         figure,
         footer,
         header,
         hgroup,
         menu,
         nav,
         section {
           display: block;
         }

         @media screen and (min-width: 35rem) {
           html {
             margin-right: calc(-100vw + 100%);
             overflow-x: hidden;
           }
         }

         ol,
         ul,
         li {
           list-style: none;
         }

         blockquote,
         q {
           quotes: none;
         }

         blockquote::before,
         blockquote::after,
         q::before,
         q::after {
           content: '';
           content: none;
         }

         table {
           border-collapse: collapse;
           border-spacing: 0;
         }

         * {
           box-sizing: border-box;
         }

         body {
           line-height: 1;
           font-size: 100%;
           font-variant-ligatures: none;
           text-rendering: optimizeLegibility;
           text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
           font-weight: 400;
         }

         img {
           display: block;
           width: 100%;
           height: auto;
         }

         button,
         input,
         textarea,
         select {
           font-family: inherit;
           font-size: inherit;
           background: none;
           border: none;
           appearance: none;
           border-radius: 0;
           resize: none;
           &:invalid {
             box-shadow: none;
           }
           &:focus {
             outline: 5px auto #2e629e;
             outline: 5px auto -webkit-focus-ring-color;
           }
         }

         body:not(.user-is-tabbing) button:focus,
         body:not(.user-is-tabbing) input:focus,
         body:not(.user-is-tabbing) select:focus,
         body:not(.user-is-tabbing) textarea:focus,
         body:not(.user-is-tabbing) a:focus {
           outline: none;
         }

         /* Sticky Infos */
         .scroll-up .bar-info {
           transform: none;
         }

         .my-button {
           background-color: transparent;
           box-shadow: inset 0 0 0 1px #2e629e;
           border-radius: 4px;
           height: 2rem;
           border: none;
           text-shadow: none;
           transition: all 10ms;
         }

         .hiddeMenu {
           transform: translateY(-330%);
           opacity: 0;
         }
         .hiddeHeader {
           height: 50px !important;
         }
         .clickedBurger {
           color: #515d6d !important;
         }

         /* Progress Bar Colors */

         .representation {
           background-color: #0bc3a7;
         }
         .personnes-concernees {
           background-color: #ffb400;
         }
         .feelgood {
           background-color: #2e629e;
         }
         .militant {
           background-color: #d83a36;
         }
         .deconstruction {
           background-color: #eb7138;
         }
         .disabled {
           background-color: #c9c9c9;
           box-shadow: inset 0px 0px 2px 7px #f3f3f3;
           width: 100% !important;
         }

         /* Cookies */
         .cookie-container {
           bottom: 0px;
           background: #383838cf;
           color: white;
           display: flex;
           flex-wrap: wrap;
           justify-content: space-between;
           left: 0px;
           position: fixed;
           width: 100%;
           z-index: 999;
         }
         .cookie-content {
           display: flex;
           align-items: center;
           flex-wrap: wrap;
           flex: 1 0 300px;
           margin: 15px;
         }
         .cookie-btn {
           font-size: 13px;
           background: black;
           border: 0px;
           border-radius: 3px;
           box-shadow: none;
           color: #fff;
           cursor: pointer;
           flex: 0 0 auto;
           padding: 5px 10px;
           margin: 15px;
         }
         .cookie-btn:hover {
           opacity: 0.8;
         }
       `
