import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import { FaFacebook, FaInstagram } from 'react-icons/fa'

const activeLinkStyle = {
  color: 'white',
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
  background: ${props => props.theme.colors.primary};
  color: darkgray;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 1rem 0 0;
  margin: 0 4rem 0.4rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex-direction: row;
  }
`

const Item = styled.li`
  display: inline-block;
  padding: 1rem 1rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    text-decoration: none;
    color: darkgray;
    font-weight: 600;
    transition: all 0.2s;
    &:hover {
      color: ${props => props.theme.colors.background};
    }
  }
`
const Socials = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 20vw;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: 15vw;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    width: 10vw;
  }
`
const SocialIcon = styled.a`
  margin-bottom: 1.2rem;
  font-size: 2rem;
  color: #515d6d;
  cursor: pointer;
  &:hover {
    color: darkgray;
  }
`

const Copyright = styled.p`
  margin: 0 auto 12px auto;
  font-size: 0.8rem;
  color: #515d6d;
  cursor: default;
`

const Footer = () => {
  const { footerLinks } = useSiteMetadata()
  const facebookUrl = 'https://www.facebook.com/lesQueeriersduCinema'
  const instagramUrl = 'https://www.instagram.com/lesqueeriersducinema/?hl=fr'
  const currentYear = new Date().getFullYear()

  return (
    <footer>
      <Wrapper>
        <List>
          <Item>
            <Link
              to={footerLinks[0].slug}
              activeStyle={activeLinkStyle}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              {footerLinks[0].name}
            </Link>
          </Item>
          <Item>
            <Link
              to={footerLinks[1].slug}
              activeStyle={activeLinkStyle}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              {footerLinks[1].name}
            </Link>
          </Item>
          <Item>
            <Link
              to={footerLinks[2].slug}
              activeStyle={activeLinkStyle}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              {footerLinks[2].name}
            </Link>
          </Item>
        </List>
        <Socials>
          <SocialIcon
            href={facebookUrl}
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
          >
            <FaFacebook />
          </SocialIcon>
          <SocialIcon
            href={instagramUrl}
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <FaInstagram />
          </SocialIcon>
        </Socials>
        <Copyright>© {currentYear} Les Queeriers du cinéma</Copyright>
      </Wrapper>
    </footer>
  )
}

export default Footer
