import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  cursor: default;
  margin: 0 auto auto;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 3rem 1.5rem 2rem;
  flex-grow: 1;
`

const Container = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Container
