import React from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'

import { FaSearch } from 'react-icons/fa'

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className}>
      <input
        className="SearchInput"
        type="text"
        placeholder="Rechercher..."
        aria-label="Recherche"
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
      <FaSearch className="SearchIcon" />
    </form>
  )
)
