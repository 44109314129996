import React from 'react'
import CookieConsent from 'react-cookie-consent'

const Cookie = () => {
  return (
    <CookieConsent
      disableStyles={true}
      containerClasses="cookie-container"
      contentClasses="cookie-content"
      buttonClasses="cookie-btn"
      buttonText="J'ai compris !"
    >
      <span role="img" aria-label="cookie">
        🍪&nbsp;&nbsp;
      </span>
      Ce site Web utilise des cookies pour améliorer l'expérience
      utilisateur.&nbsp;&nbsp;
    </CookieConsent>
  )
}

export default Cookie
