import { Link } from 'gatsby'
// eslint-disable-next-line import/no-named-default
import { default as React } from 'react'
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from 'react-instantsearch-dom'
import { FaSadCry } from 'react-icons/fa'

const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} résultat{hitCount !== 1 ? `s` : ``}
    </div>
  ) : (
    <div>
      <FaSadCry />
    </div>
  )
})

const PageHit = ({ hit }) => (
  <div>
    <Link to={`/${hit.slug}`}>
      {/* <Link to={hit.slug}> */}
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
    {/* <Snippet attribute="slug" hit={hit} tagName="mark" /> */}
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
)

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <Hits className="Hits" hitComponent={PageHit} />
  </Index>
)

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
    <PoweredBy />
  </div>
)

export default SearchResult
