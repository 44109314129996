import styled, { css } from 'styled-components'
import SearchResult from './Search-result'

const Popover = css`
  flex-direction: column;
  justify-content: space-between;
  max-height: 30vh;
  overflow: scroll;
  z-index: 9999;
  margin-top: 0.5rem;
  width: 80vw;
  box-shadow: 0px 0px 5px 0px;
  padding: 1rem;
  border-radius: 2px;
  background: ${({ theme }) => theme.background};
  svg {
    font-size: 3rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.responsive.small}) {
    align-items: center;
    flex-direction: column;
    height: 30vh;
    max-height: 80vh;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    right: -150px;
    top: 100%;
    max-width: 30rem;
  }
  @media screen and (min-width: 965px) {
    flex-direction: column;
    height: 30vh;
    max-height: 80vh;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    right: 0;
    top: 100%;
    max-width: 30rem;
  }
`

export default styled(SearchResult)`
  display: ${props => (props.show ? `flex` : `none`)};
  ${Popover}

  .HitCount {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }

  .Hits {
    ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin-left: 0;
    }

    li.ais-Hits-item {
      font-family: 'Montserrat', 'sans-serif';
      margin-bottom: 1rem;
      padding-left: 2.7rem;
      padding-right: 0.6rem;
      @media screen and (min-width: ${({ theme }) => theme.responsive.small}) {
        padding: auto;
        margin: auto;
        margin-bottom: 1rem;
      }
      .ais-Highlight-highlighted {
        padding: 0;
        background-color: ${({ theme }) => theme.markedColor};
      }
      &:first-of-type {
        text-decoration: none;
        margin-bottom: 1rem;
      }
      a {
        color: ${({ theme }) => theme.foreground};

        h4 {
          margin-bottom: 0.2rem;
        }
      }
    }
  }

  .ais-PoweredBy {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 40%;
    .ais-PoweredBy-logo {
      width: 3rem;
    }
    svg {
      width: 70px;
    }
  }
  .ais-PoweredBy-text {
    margin-right: 3px;
  }
`
