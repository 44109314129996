import algoliasearch from 'algoliasearch/lite'
// eslint-disable-next-line import/no-named-default
import { createRef, default as React, useState } from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import { ThemeProvider } from 'styled-components'
import StyledSearchBox from './styled-search-box'
import StyledSearchResult from './styled-search-result'
import StyledSearchRoot from './styled-search-root'
import useClickOutside from './use-click-outside'

const randomNumber = Math.floor(Math.random() * Math.floor(7))

// const colors = ['#D83A36', '#EB7138', '#2E629E', '#0BC3A7', '#FFB400']
const colors = [
  '#e87373',
  '#e873e7',
  '#9373e8',
  '#73bfe8',
  '#73e8c5',
  '#e8e773',
  '#e8b173',
]
const randomColor = colors[randomNumber]

const theme = {
  white: 'white',
  black: 'black',
  grey: 'darkgray',
  foreground: '#050505',
  background: 'white',
  darkBg: '#ffffff30',
  faded: '#888',
  markedColor: randomColor,

  responsive: {
    small: '35rem',
    medium: '50rem',
    large: '70rem',
  },
}

export default function Search({ indices }) {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  )

  useClickOutside(rootRef, () => setFocus(false))

  return (
    <ThemeProvider theme={theme}>
      <StyledSearchRoot
        ref={rootRef}
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
          <StyledSearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
          <StyledSearchResult
            show={query && query.length > 0 && hasFocus}
            indices={indices}
          />
        </InstantSearch>
      </StyledSearchRoot>
    </ThemeProvider>
  )
}
