import styled, { css } from 'styled-components'
import SearchBox from './Search-box'

const open = css`
  width: 10rem;
  height: 1.5rem;
  background: ${({ theme }) => theme.darkBg};
  cursor: text;
  margin-left: -1.6rem;
  padding-left: 1.6rem;
`

const closed = css`
  width: 0;
  /* background: transparent; */
  cursor: pointer;
  margin-left: -1.7rem;
`

export default styled(SearchBox)`
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;

  .SearchInput {
    outline: none;
    border: none;
    font-size: 1rem;
    transition: 100ms;
    border-radius: 20px;
    padding-right: 30px;
    color: ${({ theme }) => theme.grey};
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
    ${({ hasFocus }) => (hasFocus ? open : closed)}
    transition: all 200ms;
    &:hover {
      cursor: pointer;
      border: none;
      background: #ffffff30;
    }
  }

  .SearchIcon {
    width: 1rem;
    margin: 0.3rem;
    color: ${({ theme }) => theme.grey};
    pointer-events: none;
  }
`
